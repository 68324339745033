<template>
    <tbody>
         <tr>
            <td>
                <span class="inline-block btn btn-default margin-right pull-left"
                      :class="[`btn-sm`]"
                      id="issuerow"
                      @click="showIssueView">
                    {{issue.name}}
                </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
export default {
    props: {
        issue: {
            type: Object,
            required: true,
        },
    },
   methods: {
        showIssueView() {
            this.$emit('show-issue-view', this.issue)
        }
    },
}
</script>

<style scoped>

tr.paddingBottom>td{
    padding-bottom: 1em;
}

#issuerow{
    max-width: 100%;
    overflow: hidden;
}

</style>
