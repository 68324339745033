<template>
  <div id="app">
    <Review />
  </div>
</template>

<script>
import Review from './components/Review.vue'

export default {
  name: 'App',
  components: {
    Review
  }
}
</script>

<style>
</style>
