<template>
    <div class="api-errors">
        <div v-for="(errorObject, key) in errors" :key="key" class="alert alert-danger" role="alert">
            <strong>{{ key }}:</strong>
            <div v-if="Array.isArray(errorObject)">
                <ul v-if="errorObject.length > 1">
                    <li v-for="err in errorObject" :key="err">
                        {{ err }}
                    </li>
                </ul>
                <template v-else>
                    {{ errorObject[0] }}
                </template>
            </div>
            <div v-else>
                {{ errorObject }}
            </div>
        </div>
    </div>
</template>

<script>
// Displays all errors in one place
import { capitalize } from '../lib/utils.js'
export default {
    props: {
        errors: {
            type: [Object, Array],
            default: () => ({}),
        },
    },
    methods: {
        capitalize: capitalize
    }
}
</script>
