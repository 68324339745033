<template>
  <div v-if="data_ready">
    <div class="header">
      <h4><b>Data Element name pattern check</b></h4>
    </div>
    <div v-if="hasOCName" class="form-check">
        <p>Has associated name in Object Class &nbsp; <span class="glyphicon glyphicon-ok" style="color:green"></span></p>
    </div>
    <div v-else>
        <p>Has associated name in Object Class <span style="color:red;font-size:11pt"> &cross; </span> </p>
    </div>
    <div v-if="hasPropertyName" class="form-check">
        <p>Has associated name in Property &nbsp; <span class="glyphicon glyphicon-ok" style="color:green"></span></p>
    </div>
    <div v-else>
        <p>Has associated name in Property<span style="color:red;font-size:11pt"> &cross; </span> </p>
    </div>
    <div v-if="hasVDName" class="form-check">
        <p>Has associated name in Value Domain &nbsp; <span class="glyphicon glyphicon-ok" style="color:green"></span></p>
    </div>
    <div v-else class="no">
        <p>Has associated name in Value Domain<span style="color:red;font-size:11pt"> &cross; </span> </p>
    </div>
  </div>
  <div v-else>
    <div class="header">
      <h4><b>Data Element name pattern check</b></h4>
    </div>
    <div class="loader" />
  </div>
</template>

<script>
import Requestor from "@/apiRequest.js";

export default {
  props: {
    item: {
      type: Object,
      require: true,
    },
    url: {
      type: String,
    },
    token: {
      type: String,
    },
  },
  data: function () {
    return {
      itemData: null,
      item_name: null,
      hasOCName: false,
      hasPropertyName: false,
      hasVDName: false,
      decName: null,
      vdName: null,
      data_ready: false,
      iurl: this.url + "/api/v4/metadata/"
    };
  },
  created: function () {
    if (this.item) {
      let uurl = this.iurl + this.item["uuid"];
      this.getItemByUUID(uurl).then((response) => {
        this.itemData = response.data;
        this.item_name = this.itemData["name"];
        // check name pattern
        if (this.item_name ){
          if(this.itemData["valueDomain"] && this.itemData["dataElementConcept"]){
            let hasComma = false;
            for (var i = 0; i < this.item_name.length; i++) {
              if (this.item_name[i] === ",") {
                hasComma = true;
              }
            }
            if (hasComma) {
              let nameArray = this.item_name.split(", ");
              this.decName = nameArray[0];
              this.vdName = nameArray[1];
            }
            this.verifyHasName(this.itemData["valueDomain"], "vd");
            this.verifyHasName(this.itemData["dataElementConcept"], "dec");
          } else {
          this.data_ready = true;
        }
        }
      });
    }
  },
  methods: {
    getItemByUUID: function (uurl) {
      let requestor = new Requestor();
      let ttoken = "Token " + this.token;
      return requestor.get(uurl, ttoken).then((response) => {
        return response;
      });
    },
    verifyHasName: function (uuid, name) {
      if (name === "vd") {
        let uurl = this.iurl + uuid;
        this.getItemByUUID(uurl).then((response) => {
          if (this.vdName && this.vdName === response.data["name"]) {
            this.hasVDName = true;
          } else if(this.item_name.indexOf(response.data["name"])!==-1){
            this.hasVDName = true
          }else {
            this.hasVDName = false;
          }
          this.data_ready = true
        });
      } else {
        let uurl = this.url + "/api/v4/metadata/" + uuid;
        this.getItemByUUID(uurl).then((response) => {
          if(response.data["property"]){
            let puuid = response.data["property"];
            let purl = this.iurl + puuid;
            this.getItemByUUID(purl).then((response) => {
              let hasColumn = false;
              let hasDiv = false;
              if(this.decName){
                // check data element class name is divided by :/-
                for (var i = 0; i < this.decName.length; i++) {
                  if (this.decName[i] === ":") {
                    hasColumn = true;
                  } else if (this.decName[i] === "—") {
                    hasDiv = true;
                  }
                }
                if (hasColumn) {
                  let ndec = this.decName.split(": ");
                  if (ndec[1] === response.data["name"]) {
                    this.hasPropertyName = true;
                  }
                } else if (hasDiv) {
                  let ndec = this.decName.split("—");
                  if (ndec[1] === response.data["name"]) {
                    this.hasPropertyName = true;
                  }
                }else if( this.decName.indexOf(response.data["name"])!==-1){
                  this.hasPropertyName = true
                }
              }else if(this.item_name.indexOf(response.data["name"])!==-1){
                this.hasPropertyName = true
              }
              this.data_ready = true
            });
          }
          if(response.data["objectClass"]){
            let ocuuid = response.data["objectClass"];
            let ocurl = this.iurl + ocuuid;
            this.getItemByUUID(ocurl).then((response) => {
              let hasColumn = false;
              let hasDiv = false;
              if(this.decName){
                for (var i = 0; i < this.decName.length; i++) {
                  if (this.decName[i] === ": ") {
                    hasColumn = true;
                  } else if (this.decName[i] === "—") {
                    hasDiv = true;
                  }
                }
                if (hasColumn) {
                  let ndec = this.decName.split(": ");
                  if (ndec[0] === response.data["name"]) {
                    this.hasOCName = true;
                  }
                } else if (hasDiv) {
                  let ndec = this.decName.split("—");
                  if (ndec[0] === response.data["name"]) {
                    this.hasOCName = true;
                  }
                }else if(this.decName.indexOf(response.data["name"])!==-1){
                  this.hasOCName = true;
                }
              }else if(this.item_name.indexOf(response.data["name"])!==-1){
                this.hasOCName = true
              }
              this.data_ready = true;
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.form-check-label {
  margin-right: 10px;
  margin-bottom: 0px;
}

.check {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}

p {
  color: black;
  font-weight: normal;
  font-size: small;
  padding-left: 10px;
}

.loader {
  display: inline-block;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
</style>
