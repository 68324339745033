<template>
  <modal v-model="openModal" @hide="closeModal" append-to-body>
        <span slot="title">
            <span class="margin-left margin-right">
                <em>Viewing 
                  <div id="mhead">
                    {{ issue.name }}
                  </div>
                  </em>
            </span>
        </span>

    <table class="table">
      <colgroup>
        <col span="1" style="width: 20%">
      </colgroup>
      <tr class="paddingBottom">
        <td><b>Description: </b></td>
        <td>{{ issue.description }}</td>
      </tr>

      <tr class="paddingBottom">
        <td><b>Submitter: </b></td>
        <td>{{ issue.submitter_display_name }}</td>
      </tr>

      <tr class="paddingBottom">
        <td><b>Number of Comments: </b></td>
        <td>{{ issue.number_of_comments }}</td>
      </tr>

      <tr class="paddingBottom">
        <td><b>Proposal Field: </b></td>
        <td>{{ issue.proposal_field }}</td>
      </tr>

      <tr class="paddingBottom">
        <td><b>Proposal Value: </b></td>
        <td>{{ issue.proposal_value }}</td>
      </tr>

      <tr>
        <td><b>Stewardship Organisation</b></td>
        <td>{{ issue.stewardship_organisation_name }}</td>
      </tr>
    </table>

    <span slot="footer">
            <button class="btn btn-sm btn-outline-info" @click="closeModal">
                <i class="fa fa-times" aria-hidden="true"/> Back
            </button>
        </span>
  </modal>
</template>

<script>
import Modal from 'uiv/src/components/modal/Modal.vue'

export default {
  components: {
    Modal,
  },
  props: {
    showIssueView: {
      type: Boolean,
      required: true
    },
    issue: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    openModal: false
  }),

  mounted() {
    this.openModal = this.showIssueView
  },

  methods: {
    closeModal() {
      this.openModal = false
      this.$emit('hide-issue-view')
    },
  },
}
</script>

<style scoped>
#mhead{
  word-break: break-all;
}
</style>