import { render, staticRenderFns } from "./IssueRow.vue?vue&type=template&id=bba2b13e&scoped=true&"
import script from "./IssueRow.vue?vue&type=script&lang=js&"
export * from "./IssueRow.vue?vue&type=script&lang=js&"
import style0 from "./IssueRow.vue?vue&type=style&index=0&id=bba2b13e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bba2b13e",
  null
  
)

export default component.exports