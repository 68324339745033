<template>
      <modal v-model="openModal" @hide="closeModal" append-to-body>
        <span slot="title">
            <span class="margin-left margin-right">
                <em>Creating Issue</em>
            </span>
        </span>

        <api-errors :errors="issueError" />
        <label for='name' class="pull-left">Name</label>
        <input id="name" class="form-control" v-model="issueNameData"><br>

        <label for='description' class="pull-left">Description</label>
        <input id="description" class="form-control" v-model="issueDescriptionData">

        <!--todo: allow creation of issue with labels and proposed value and field-->

        <span slot="footer">
            <a :href="redirectUrl" type="button" class="btn btn-warning pull-left">
                Advance
            </a>

            <button type="button" class="btn btn-success" @click="createIssue">
                <div v-if="!creating">Create</div>
                <div v-else class="loader"/>
            </button> 
            
            <button class="btn btn-outline-info" @click="closeModal">
                <i class="fa fa-times" aria-hidden="true" /> Back
            </button>
        </span>
    </modal>
</template>

<script>
import apiErrors from "../apiErrorDisplay";
import Modal from 'uiv/src/components/modal/Modal.vue'
import apiRequest from "../../mixins/apiRequest";
export default {
  components: {
      Modal,
      apiErrors,
    },
  mixins:[apiRequest],
  props: {
    showIssueCreate: {
      type: Boolean,
      required: true
    },
    itemId: {
      type: Number,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    completeListCreateEndPoint: {
      type: String,
      required: true,
    },
  },

  data: ()=> ({
    openModal: false,
    creating: false,
    issue: {},
    issueError: {},
    issueNameData: '',
    issueDescriptionData: '',
    redirectUrl:'',
  }),

  created() {
    this.redirectUrl = this.url + '/item/' + this.itemId + '/issues'
  },

  mounted() {
    this.openModal = this.showIssueCreate
  },

  methods: {
    closeModal() {
            this.openModal=false
            this.$emit('hide-issue-create')
    },
    createIssue() {
            this.creating=true
            this.post(
                this.completeListCreateEndPoint,
                {'item': this.itemId,
                      'name': this.issueNameData,
                      'description': this.issueDescriptionData
                },
                'json',
                "Token " + this.token
            ).then((response) => {
                this.$emit('update-issue-list', response.data)
                this.closeModal()
            }).catch((error) => {
                this.issueError = error.response.data
            }).finally(() => {
                this.creating = false
            })
        }
  }
}
</script>

<style scoped>
    .loader {
            display: inline-block;
            border: 3px solid #f3f3f3; /* Light grey */
            border-top: 3px solid #3498db; /* Blue */
            border-radius: 50%;
            width: 15px;
            height: 15px;
            animation: spin 2s linear infinite;
    }
</style>