<template>
  <div>
    <a
      :id="item.id + uniqueIdIdentifier"
      :hover="'#h' + item.id +uniqueIdIdentifier"
      style="cursor: pointer"
      variant="primary"
      >
      {{item.item}}
    </a>
    <popover
      trigger="hover"
      :id="'h' + item.id + uniqueIdIdentifier"
      :title="item.item"
      :target="'#' + item.id + uniqueIdIdentifier"
    >
      <template slot="popover">
        <p>
          <a :href="item.eurl" target="_blank">Find it in Aristotle</a>
          <br />
          <a :href="'#' + item.uuid">Find it in this page</a>
        </p>
      </template>
    </popover>
  </div>
</template>

<script>
import { Popover } from "uiv";

export default {
  components: {
    Popover
  },
  props: {
    item: {
      type: Object,
      require: true
    },
    uniqueIdIdentifier: {
      require: false,
    }
  }
};
</script>
