<template>
  <tr v-if="dataReady" :class="emptyRender" style="padding: 2px">
    <th stickColumn="true" v-if="!hideIsEmpty">
      {{ llabel }}
    </th>
    <td width="80%" v-if="changeuuid & !hideIsEmpty">
      <item-popover 
        :item = "ddetail"
        :uniqueIdIdentifier = "itemuuid"
      />
    </td>
    <td width="80%" v-else-if="isdec">
      <div style="width: 50%" class="tree">
        <ul class="split">
          <li style="width: 60%">
            <header class="panel panel-info">
              <div class="panel-heading">
                <strong> Data Element Concept </strong>
              </div>
              <div class="panel-body name">
                <item-popover
                  :item = "dec"
                  :uniqueIdIdentifier = "'h' + itemuuid"
                />
              </div>
            </header>
            <ul v-if="oc && p" class="split">
              <li v-if="oc" class="panel panel-info">
                <div class="panel-heading"><strong> Object Class </strong></div>
                <div class="panel-body name">
                  <item-popover
                    :item = "oc"
                    :uniqueIdIdentifier = "'h' + itemuuid"
                  />
                </div>
              </li>
              <li v-if="p" class="panel panel-info">
                <div class="panel-heading"><strong> Property </strong></div>
                <div class="panel-body name">
                  <item-popover
                    :item = "p"
                    :uniqueIdIdentifier = "'h' + itemuuid"
                  />
                </div>
              </li>
            </ul>
          </li>
          <li v-if="vd" style="width: 40%">
            <header class="panel panel-info">
              <div class="panel-heading"><strong> Value Domain </strong></div>
              <div class="panel-body name">
                <item-popover
                  :item = "vd"
                  :uniqueIdIdentifier = "'h' + itemuuid"
                />
              </div>
            </header>
          </li>
        </ul>
      </div>
    </td>
    <td width="80%" v-else-if="containuuid & !hideIsEmpty">
      <a
        v-if="label === 'workgroup'"
        :href="workgroupUrl + detail"
        target="_blank"
        >{{ ddetail }}</a>
      <a
        v-else-if="label === 'stewardship_organisation'"
        :href="ddetail.url"
        target="_blank"
        >{{ ddetail.item }}</a>
      <a v-else :href="itemUrl + detail" target="_blank">{{ ddetail }}</a>
    </td>
    <td width="80%" v-else-if="isArray & !hideIsEmpty">
      <table class="arrayTable renderOverflow">
        <thead>
          <tr>
            <th v-for="key in arrayLabel" :key="key">
              {{ key.replace(/_/, " ") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="[key, item] in Object.entries(ddetail)" :key="key">
            <td
              v-for="[k, v] in Object.entries(item)"
              :key="k"
              :class="v"
              style="max-width: 1%"
            >
              <div v-if="v.uuid">
                <item-popover
                  :item = "v"
                  :uniqueIdIdentifier = "item.id + key + label"
                />
              </div>
              <div v-else-if="v.url">
                <a :href="v.url" target="_blank">{{ v.item }}</a>
              </div>
              <div v-else-if="Array.isArray(v)">
                <div v-for="d in v" :key="d.index">
                  <div v-if="d.url">
                    <a :href="d.url" target="_blank">{{ d.item }}</a>
                  </div>
                  <div v-else-if="d.uuid">
                    <item-popover
                      :item = "d"
                      :uniqueIdIdentifier = "key + 'h' + label"
                    />
                  </div>
                  <div v-else-if="ishtml(d)" v-html="d" />
                  <div v-else>
                    {{ d }}
                  </div>
                </div>
              </div>
              <div v-else-if="ishtml(v)" v-html="v" />
              <div v-else>
                {{ v }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
    <td width="80%" v-else-if="ishtml(ddetail) & !hideIsEmpty">
      <div class="renderOverflow" v-html="ddetail"></div>
    </td>
    <td width="80%" v-else-if="!hideIsEmpty">
      {{ ddetail }}
    </td>
  </tr>
  <tr v-else>
    <div class="loader" />
  </tr>
</template>

<script>
import Requestor from "@/apiRequest.js";
import ItemPopover from './ItemPopover.vue';

export default {
  components: {
    ItemPopover
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    detail: {
      default: null,
    },
    hideEmpty: {
      type: Boolean,
      required: true,
    },
    itemDetailReady: {
      type: Boolean,
    },
    grouping: {
      type: Object,
      required: true,
    },
    itemuuid: {
      type: String,
    },
  },
  data: function () {
    return {
      ddetail: this.detail,
      isArray: false,
      emptyRender: null,
      hideIsEmpty: false,
      emptyArrayRender: null,
      arrayLabel: [],
      llabel: null,
      dataReady: false,
      containuuid: false,
      changeuuid: false,
      isdec: false,
      dec: null,
      oc: null,
      p: null,
      vd: null,
      itemUrl: this.url + "/item/",
      workgroupUrl: this.url + "/workgroups/",
      // used to hide empty table
      isempty: true,
    };
  },
  mounted: function () {
    // handle show/hide empty value
    if (this.hideEmpty) {
      if (
        this.detail == null ||
        this.detail.length === 0 ||
        this.detail == ""
      ) {
        this.hideIsEmpty = true;
      }
    } else {
      this.hideIsEmpty = false;
    }

    // change empty value
    if (
      this.detail === null ||
      this.detail.length === 0 ||
      this.detail === ""
    ) {
      this.ddetail = "/";
      this.emptyRender = "table-light";
    }

    // format label name
    this.llabel = this.label.replace(/_/, " ");
    this.llabel = this.llabel.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    if (this.llabel === "Dataelementconcept") {
      this.llabel = "Data Element Concept";
    } else if (this.llabel === "Valuedomain") {
      this.llabel = "Value Domain";
    } else if (this.llabel === "Metadatareferencelink Set") {
      this.llabel = "Metadata Reference Link Set";
    } else if (this.llabel === "Customvalue Set") {
      this.llabel = "Custom Value Set";
    } else if (this.llabel === "Distributiondataelementpath Set") {
      this.llabel = "Distribution Data Element Path Set";
    } else if (this.llabel === "Unit Of_measure") {
      this.llabel = "Unit of Measure";
    } else if (this.llabel === "Permissiblevalue Set") {
      this.llabel = "Permissible Value Set";
    } else if (this.llabel === "Supplementaryvalue Set") {
      this.llabel = "Supplementary Value Set";
    } else if (this.llabel === "Objectclass") {
      this.llabel = "Object Class";
    } else if (this.llabel === "Conceptualdomain") {
      this.llabel = "Conceptual Domain";
    }

    let u = this.detail;
    // change uuid to name
    if (this.label === "dataElementConcept" && this.ddetail != "/" && Object.keys(this.grouping).length > 0) {
      // draw tree map for dec
      this.isdec = true;
      this.dec = this.renderItemPopover(this.grouping[u][0], u)
      if (this.grouping[u]["property"]) {
        this.p = this.renderItemPopover(this.grouping[u]["property"], this.grouping[u]["puuid"])
      }
      if (this.grouping[u]["objectClass"]) {
        this.oc = this.renderItemPopover(this.grouping[u]["objectClass"], this.grouping[u]["ocuuid"])
      }
      if (this.grouping[this.itemuuid]["valueDomain"]) {
        this.vd = this.renderItemPopover(this.grouping[this.itemuuid]["valueDomain"], this.grouping[this.itemuuid]["vuuid"])
      }
      this.dataReady = true;
    } else if (
        this.label === "stewardship_organisation" &&
        this.ddetail != "/"
    ) {
        let uurl = this.url + "/api/internal/steward/";
        this.getItemByUUID(uurl).then((response) => {
          for (let index in response.data) {
            if (
              response.data[index]["uuid"].replace(/-/g, "") === this.detail
            ) {
              this.ddetail = {};
              this.ddetail.item = response.data[index]["name"];
              this.ddetail.url =
                this.url +
                "/steward/" +
                this.ddetail.item.toLowerCase().replaceAll(" ", "-") +
                "/";
              this.containuuid = true;
            }
          }
        }).catch(() =>{
          this.dataReady = true
        }).finally(() => {
          this.dataReady = true
        });
    } else if (
      Array.isArray(this.ddetail) &&
      this.ddetail.length > 0 &&
      this.ddetail != "/"
    ) {
      // parameters for change specialisationclass from uuid to name
      let hassc = false;
      let spromises = [];
      let indexes = [];
      this.isArray = true;
      // drop customevalue_set's id and field
      if (this.label === "customvalue_set" && this.ddetail != "/") {
        for (let i in this.ddetail) {
          delete this.ddetail[i]["id"];
          delete this.ddetail[i]["field"];
          if (this.ddetail[i]["content"] != "") {
            this.isempty = false;
          } else {
            this.ddetail[i]["content"] = "empty";
          }
        }
        if (this.isempty) {
          if (this.hideEmpty) {
              this.hideIsEmpty = true;
            } else {
              this.hideIsEmpty = false;
            }
            this.emptyRender = "table-light";
          }
          this.dataReady = true;
        } else {
          this.isempty = false;
          for (let index in this.ddetail) {
            for (let iindex in this.ddetail[index]) {
              delete this.ddetail[index]["id"];
              if (
                this.ddetail[index][iindex] == null ||
                this.ddetail[index][iindex] === [] ||
                this.ddetail[index][iindex] == ""
              ) {
                this.ddetail[index][iindex] = "empty";
              }
              if (iindex === "value_meaning" && Object.keys(this.grouping).length > 0) {
                for (let i in this.grouping) {
                  if (
                    this.ddetail[index][iindex] === this.grouping[i]["vmuuid"]
                  ) {
                    this.ddetail[index][iindex] = this.grouping[i]["vm"];
                  }
                }
              } else if (iindex === "linked_group" && Object.keys(this.grouping).length > 0) {
                for (let g in this.ddetail[index][iindex]) {
                  if (this.ddetail[index][iindex] != "empty") {
                    this.ddetail[index][iindex][g] = this.grouping[this.ddetail[index][iindex][g]];
                  }
                }
              } else if (iindex === "data_element" || iindex === "child") {
                // change uuid in array to item's name
                if(this.grouping[this.ddetail[index][iindex]]){
                  this.ddetail[index][iindex] = this.renderItemPopover(this.grouping[this.ddetail[index][iindex]][0], this.grouping[this.ddetail[index][iindex]][2])
                }else{
                  if(iindex === "data_element"){
                    this.getItemByUUID(
                      this.url + "/api/v4/metadata/dataelement/" + this.ddetail[index][iindex]
                    ).then((response) => {
                      this.ddetail[index][iindex] = this.renderItem(this.ddetail[index][iindex], response)
                    })                    
                  }else{
                    this.getItemByUUID(
                      this.url + "/api/v4/metadata/datasetspecification/" + this.ddetail[index][iindex]
                    ).then((response) => {
                      this.ddetail[index][iindex] = this.renderItem(this.ddetail[index][iindex], response)
                    })
                  }
                }
              } else if (iindex === "specialisation_classes") {
                let oneRecord = this.ddetail[index][iindex]
                hassc = true;
                if (
                  Array.isArray(oneRecord) && oneRecord.length > 1
                ) {
                  for (let s in oneRecord) {
                    if (oneRecord != "empty") {
                      let sc =oneRecord[s]
                      if(this.grouping[sc]){
                        this.ddetail[index][iindex][s] = this.renderItemPopover(this.grouping[sc][0], this.grouping[sc][2])
                      }else{
                        indexes.push([index, iindex, s]);
                        spromises.push(
                          this.getItemByUUID(
                            this.url + "/api/v4/metadata/objectclass/" + sc
                          )
                        ); 
                      }
                    }
                  }
                } else {
                  if (oneRecord != "empty") {
                    let sc = oneRecord
                    if(this.grouping[sc]){
                      this.ddetail[index][iindex] = this.renderItemPopover(this.grouping[sc][0], this.grouping[sc][2])
                    }else{
                      indexes.push([index, iindex]);
                      spromises.push(
                        this.getItemByUUID(
                          this.url +
                            "/api/v4/metadata/objectclass/" + oneRecord[0]
                        )
                      );
                    }
                  }
                }
              }
            }
          }
          if (this.ddetail) {
            this.arrayLabel = Object.keys(this.ddetail[0]);
          }
          // handle uuid in specialisation classes
          if (hassc) {
            if (spromises != []) {
              Promise.all(spromises).then((s) => {
                for (let order in s) {
                  if (indexes[order].length === 3) {
                    this.ddetail[indexes[order][0]][indexes[order][1]][
                      indexes[order][2]
                    ] = this.renderItem(
                          this.ddetail[indexes[order][0]][indexes[order][1]][indexes[order][2]], s[order]
                        );
                  } else {
                    this.ddetail[indexes[order][0]][indexes[order][1]] = this.renderItem(this.ddetail[indexes[order][0]][indexes[order][1]], s[order])
                  }
                }
              }).finally(() => {
                this.dataReady = true
              });
            }
          } else {
            this.dataReady = true;
          }
        }
    } else if ( 
        // change uuid to name from grouping, if it's existed in this page, add a point to find it in this page
        this.grouping[u] &&
        this.label != "uuid" &&
        this.label != "id" &&
        this.label != "dataElementConcept"
    ) {
        this.ddetail = {};
        this.ddetail.item = this.grouping[u][0];
        this.ddetail.id = "id" + this.ddetail.item.replaceAll(" ", "");
        this.ddetail.eurl = this.itemUrl + this.detail;
        this.dataReady = true;
        this.changeuuid = true;
    }else if(
        // find the existing uuid and change it to name
        this.ddetail != "/" && this.label != "uuid" && /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(this.detail)
    ){
      this.containuuid = true
      if(this.grouping[this.detail]){
          this.ddetail = this.renderItemPopover(this.grouping[this.detail][0], this.detail)
          this.dataReady = true
        }else{
          let uurl = this.url + "/api/v4/metadata/" + this.detail;
          this.getItemByUUID(uurl).then((response) => {
            this.ddetail = response.data["name"];
          this.dataReady = true;
        }).catch(() => {
          this.dataReady = true
        }).finally(() => {
          this.dataReady = true
        }); 
      }
    }else{
      this.dataReady = true
    } 
  },
  watch: {
    hideEmpty() {
      if (this.hideEmpty) {
        if (
          this.detail == null ||
          this.detail === [] ||
          this.detail == "" ||
          (this.isempty && this.isArray)
        ) {
          this.hideIsEmpty = true;
        }
      } else {
        this.hideIsEmpty = false;
      }
    },
  },
  methods: {
    getItemByUUID: function (uurl) {
      let requestor = new Requestor();
      let ttoken = "Token " + this.token;
      return requestor.get(uurl, ttoken).then((response) => {
        return response;
      });
    },
    ishtml: function(data) {
      if (/<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(data)) {
          return true
      }
    },
    renderItemPopover: function(iName, iId){
      let item = {}
      item.item = iName
      item.id = "id" + iName.replaceAll(" ", "") + iId
      item.eurl = this.itemUrl + iId
      item.uuid = iId
      return item
    },
    renderItem: function (id, response) {
      let newItem = {}
      newItem.item = response.data["name"]
      newItem.url = this.url + "/item/" + id
      return newItem
    }
  },
};
</script>

<style scoped>
.panel-info {
  background-color: white;
  font-size: 14px;
  text-align: center;
  border: 1px solid #dddddd;
}

.panel-info > .panel-heading {
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: white;
  color: #333;
  border-bottom: 1px solid #dddddd;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding-top: 2px;
  padding-bottom: 2px;
}

ul {
  list-style: none;
}

.split {
  width: 100%;
  overflow: auto;
  text-align: center;
  display: table;
}

.split > * {
  display: table-cell;
}

.split > .panel-info > .panel-heading {
  color: #333;
}

.table-light {
  background-color: rgba(0, 0, 0, 0.05);
}

.empty {
  background-color: rgba(0, 0, 0, 0.05);
}

.renderOverflow {
  width: 1000px;
  max-width: 80%;
  white-space: nowrap;
  overflow: auto;
  background:
    /* Shadow covers */
    linear-gradient(to right, white 30%, rgba(255,255,255,0)) left,
    linear-gradient(to right, rgba(255,255,255,0), white 70%) right,

    /* Shadows */
    radial-gradient(farthest-side at left, rgba(0,0,0,.3), transparent) left,
    radial-gradient(farthest-side at right, rgba(0,0,0,.3), transparent) right,
    white;

  /* Sizing and attachment of layers above */
  background-size: 40px 100%, 40px 100%, 15px 100%, 15px 100%, 100% 100%;
  background-attachment: local, local, scroll, scroll;
  background-repeat: no-repeat;
}

.arrayTable {
  display: block;
  table-layout: fixed;
}

.arrayTable > thead > tr > th {
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  word-break: normal;
}

.arrayTable > tbody > tr > td {
  padding-left: 5px;
  padding-right: 5px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  overflow: auto;
}

.loader {
  display: inline-block;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
</style>
