import axios from 'axios'
import { getCSRF } from '../lib/cookie.js'

export default function(method, url, data, params, response_type = 'json', authentication_token="") {
    let csrf_token = getCSRF()
    let promise = axios({
        method: method,
        url: url,
        data: data,
        params: params,
        responseType: response_type,
        headers: {'X-CSRFToken': csrf_token,
                  'Authorization': authentication_token}
    })
    return promise
}
