<template>
  <div v-if="data_ready">
    <br />
    <div class="reviewName">
      <strong>
       Review name: <a :href="reviewUrl" target="_blank">{{reviewTitle}}</a>
      </strong>
    </div>
    <div v-for="index in Object.keys(itemList)" :key="index.key">
      <div
        class="dataDisplay"
        v-for="item in itemList[index]"
        :key="item.index"
      >
        <div class="sidebar">
          <div v-if="item['item_type_name'] === 'Data Element'" class="checkC">
            <check-component :item="item" :url="url" :token="token" />
          </div>
          <div class="issueC sticky_header">
            <issue-table
                :item-id="item['id']"
                :token="token"
                :url="url"
            />
          </div>
        </div>
        <div class="dataContent">
          <data-component
            :url = "url"
            :token="token"
            :itemGroup="index"
            :hideEmpty="hideEmpty"
            :item="item"
            :itemSet = "itemSet"
            :grouping = "grouping"
            @ready="dataReady"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Requestor from "@/apiRequest.js";

import dataComponent from "@/components/DataComponent.vue";
import checkComponent from "@/components/CheckComponent.vue";
import IssueTable from "./IssueComponents/IssueTable.vue";

export default {
  components: {
    IssueTable,
    dataComponent,
    checkComponent,
  },
  props: {
    requestList: {
      type: Object,
      requred: true,
    },
    reviewUrl: {
      type: String,
    },
    url: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    hideEmpty: {
      type: Boolean,
    },
  },
  data: () => {
    return {
      itemReadyCount: 0,
      requestListLength: 0,
      itemDetailReady: false,
      completeRequest: [],
      isDataElement: false,
      hasModalOpened: false,
      // generate name, uuid set to store items
      itemSet: {},
      data_ready:false,
      // store names with uuid to change uuid to name (name, uuid mapping)
      grouping: {},
      hasCascade: false,
      completeUrl: [],
      itemList: [],
      reviewTitle: '',
    };
  },
  created: function () {
    this.reviewTitle = this.requestList['title']
    this.itemList['items_in_review'] = this.requestList['items_in_review']
    if(this.requestList['cascaded_items']){
      this.hasCascade = true
      this.itemList['cascaded_items'] = this.requestList['cascaded_items']      
    }
    for (let index in this.itemList) {
      this.requestListLength += this.itemList[index].length;
    }
    // change requestlist to an array
    let promises = []
    for (let index in this.itemList) {
      this.itemList[index].sort((a, b) =>
        a.item_type_name > b.item_type_name ? 1 : -1
      );
      for (let iindex in this.itemList[index]) {
        let item = this.itemList[index][iindex];
        // get item data for the review
        let uurl = this.url + "/api/v4/metadata/" + item["uuid"];
        this.iurl = this.url + "/item/" + item["id"];
        promises.push(this.getItemByUUID(uurl))
      }
    }
    if(!this.hasCascade){
      this.completeRequest = this.itemList['items_in_review']
      Promise.all(promises).then((r) => {
        for(let index in r){
          this.itemSet[r[index].data["uuid"]] = r[index].data
        }
      }).finally(() => {
        this.data_ready = true
      })
    }else{
      this.completeRequest = this.itemList['items_in_review'].concat(this.itemList['cascaded_items'])
      Promise.all(promises).then((r) => {
          for(let index in r){
            this.itemSet[r[index].data["uuid"]] = r[index].data
            this.grouping[r[index].data["uuid"]] = [r[index].data["name"], r[index].data["id"], r[index].data["uuid"]]
            this.grouping[r[index].data["id"]] = [r[index].data["name"], r[index].data["id"], r[index].data["uuid"]]
            this.grouping[r[index].data["uuid"]]["uuid"] = r[index].data["uuid"]
            // add groups name
            if(r[index].data['groups']){
              for(let g in r[index].data['groups']){
                this.grouping[r[index].data['groups'][g]["id"]] = r[index].data['groups'][g]["name"]
              }
            }
          }
          let vmpromises = []
          let pid = []
          // store property and objectclass to data elment concept
          for(let index in r){
            if(this.completeRequest[index].item_type_name === 'Data Element Concept'){
              let ocUuid = r[index].data["objectClass"]
              let pUuid = r[index].data["property"]
              let pName = null
              let ocName = null
              for(let iindex in r){
                if(r[iindex].data["uuid"] == pUuid){
                  pName = r[iindex].data["name"]
                }
                if(r[iindex].data["uuid"] == ocUuid){
                  ocName = r[iindex].data["name"]
                }
              }
              this.grouping[r[index].data["uuid"]]["puuid"] = pUuid
              this.grouping[r[index].data["uuid"]]["ocuuid"] = ocUuid
              this.grouping[r[index].data["uuid"]]["property"] = pName
              this.grouping[r[index].data["uuid"]]["objectClass"] = ocName
            }
            if(this.completeRequest[index].item_type_name === 'Data Element'){
              let vUuid = r[index].data["valueDomain"]
              if(vUuid){
                let vName = this.grouping[vUuid][0]
                this.grouping[r[index].data["uuid"]]["valueDomain"] = vName
                this.grouping[r[index].data["uuid"]]["vuuid"] = vUuid
              }
              if(this.itemSet[r[index].data["uuid"]].dataElementConcept && this.itemSet[r[index].data["uuid"]].valueDomain){
                delete this.itemSet[r[index].data["uuid"]].valueDomain
              }
            }
            if(this.completeRequest[index].item_type_name === 'Value Domain'){
              if(r[index].data["permissiblevalue_set"] != []){
                let pset = r[index].data["permissiblevalue_set"]
                let c = r[index].data["conceptual_domain"]
                for(let i in pset){
                  pid.push(pset[i]["value_meaning"])
                }
                if(c){
                  vmpromises.push(this.getItemByUUID(this.url + "/api/v4/metadata/" + c))
                }
              }
            }
          }
          Promise.all(vmpromises).then((vms) => {
            // change valuemeanning to name from uuid
            for(let vm in vms){
              for(let vid in vms[vm].data["valuemeaning_set"]){
                for(let p in pid){
                  if(pid[p] === vms[vm].data["valuemeaning_set"][vid].id){
                    this.grouping[r[Object.keys(pid[p])[p]].data["uuid"]]["vmuuid"] = pid[p]
                    this.grouping[r[Object.keys(pid[p])[p]].data["uuid"]]["vm"] = vms[vm].data["valuemeaning_set"][vid].name
                  }
                }
              }
            }
          }).finally(() => {
            this.data_ready = true
          })
      })
    }
  },
  methods: {
    getItemByUUID: function (uurl) {
      let requestor = new Requestor();
      let ttoken = "Token " + this.token;
      return requestor.get(uurl, ttoken).then((response) => {
        return response;
      }).catch((error) => {
        this.$emit("errorHitting", error, uurl)
      });
    },
    dataReady: function () {
      this.itemReadyCount += 1;
    },
  },
  watch: {
    itemReadyCount() {
      if (this.itemReadyCount == this.requestListLength) {
        this.itemDetailReady = true;
        this.$emit("detailsReady");
      }
    },
  },
};
</script>

<style scoped>
.reviewName {
  text-align: left;
  padding-left: 20.5%;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0.05);
  border-top: 1px solid #dddddd;
}

.dataDisplay {
  height: auto;
  display: flex;
  flex-direction: row;
  border: 1px solid #dddddd;
  border-top: none;
  border-left: none;
}

.sidebar {
  flex: 0 0 20%;
  height: auto;
  float: left;
  flex-direction: column;
  border-top: 1px solid #dddddd;
  background-color: rgba(0, 0, 0, 0.05);
}

.checkC {
  position: relative;
  flex: 1 1 auto;
  border-bottom: 1px solid #dddddd;
}

.issueC {
  height: auto;
  width: 100%;
  float: left;
}

.sticky_header {
  background: white;
  position: sticky;
  top: 0;
}

.dataContent {
  width: 100%;
  flex: 1 1 auto;
  padding: 10px;
  overflow: auto;
  display: inline-block;
}
</style>
