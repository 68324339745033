<template>
  <div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col" class="sticky_header">
          <div>
            <h5><strong class="pull-left">Open Issues</strong></h5>
            <button v-if="itemId" class="btn btn-success pull-right" @click="showIssueCreateMethod">
              New Issue
            </button>
          </div>
        </th>
      </tr>
      </thead>
      <!--component to display issue name row by row-->
      <api-errors :errors="issueError" />
      <div v-if="loading" class="loader"/>
      <issue-row
          v-for="issue in issuesList" :key="issue.id"
          :issue="issue"
          @show-issue-view="showIssueViewMethod"
      />
    </table>

    <!--modal component to view issue details-->
    <issue-view
        v-if="showIssueView"
        :issue="issue"
        :show-issue-view="showIssueView"
        @hide-issue-view="hideIssueView"
    />

    <!--modal component to create a new issue-->
    <issue-create
        v-if="showIssueCreate"
        :item-id="itemId"
        :token="token"
        :complete-list-create-end-point="completeListCreateEndPoint"
        :show-issue-create="showIssueCreate"
        :url="url"
        @hide-issue-create="hideIssueCreate"
        @update-issue-list="updateIssueList"
    />
  </div>
</template>

<script>

import apiRequest from "../../mixins/apiRequest";
import apiErrors from "../apiErrorDisplay";
import IssueRow from "./IssueRow.vue";
import IssueCreate from "./IssueCreate.vue";
import IssueView from "./IssueView.vue";
export default {
    components: {
        IssueRow,
        IssueCreate,
        IssueView,
        apiErrors,
    },
    props: {
        itemId: {
            type: Number,
            required: true,
        },
        token: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },

    mixins:[apiRequest],

    data: () => ({
            issuesListCreateAPIEndPoint: "/api/v4/issues/",
            issuesList: [],
            issuesErrors: {},
            issue:{},
            showIssueCreate: false,
            showIssueView: false,
            issueError: {},
            loading: false,
            completeListCreateEndPoint: ''
        }

    ),

    mounted() {
        this.getIssuesList()
        this.completeListCreateEndPoint= this.url + this.issuesListCreateAPIEndPoint
    },

    methods: {
        showIssueViewMethod(value) {
          this.showIssueView=true
          this.issue = value
        },

        hideIssueView() {
          this.showIssueView = false
        },

        showIssueCreateMethod() {
          this.showIssueCreate=true
        },

        hideIssueCreate() {
          this.showIssueCreate = false
        },

        getIssuesList() {
            this.loading = true
            this.get(
                this.url + this.issuesListCreateAPIEndPoint,
                {'item': this.itemId,
                        'isopen': true},
                "Token " + this.token

            ).then((response) => {
                this.issuesList = response.data.results
            }).catch((error) => {
                this.issueError = error.response.data
            }).finally(() => {
                this.loading = false
            })
        },
        updateIssueList(value){
          this.issuesList.unshift(value)
        },
    }
}
</script>

<style scoped>
.table{
  background-color: rgba(0,0,0,.05);
  margin-bottom: 0;
  table-layout: fixed;
}

.sticky_header {
  background-color: #f1f1f1;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.loader {
  display: inline-block;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

</style>
