<template>
    <div>
        <div id="content">
            <!-- url needs to be in the format of "http://localhost:8000" -->
            <label for="url">Registry URL:</label>
            <input class="url" v-model="url" placeholder="example: http://localhost:8000" /><br><br>
            <label for="token">API Token:</label>
            <input class="token" type="text" v-model="token" placeholder=" "><br><br>
            <label for="id">ID of Review:</label>
            <input class="id" type="number" v-model="id" placeholder=" "><br><br>
            <div class="error" v-if="error">
                {{errorMessage}}
            </div>
            <button type="Submit" class="btn btn-primary" @click="viewReview" style="margin-right: 10px">
                Review
            </button>
                <div v-if="loading" class="alert alert-primary" role="alert">
                    Metadata is loading. Please wait until it's completed <div class="loader"/> 
                </div>
            <button v-if="submit & showHideButton" type="button" class="btn btn-light brn-sm" @click="emptyClicked">
                {{emptyValue}}
            </button>
            <div v-if="submit">
                <ShowList 
                    :requestList="requestList"
                    :url="url"
                    :token="token"
                    @detailsReady="detailsReady"
                    @errorHitting="handleError"
                    :hideEmpty="hideEmpty"
                    :reviewUrl="url + '/review/' + id"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Requestor from '@/apiRequest.js'
import ShowList from '@/components/ShowList.vue'
export default {
    components: {
      ShowList,
    },
    data: function() {
        return {
            url: null,
            token: null,
            id: null,
            submit: false,
            hideEmpty: true,
            emptyValue: "Show Empty Value",
            requestList: {},
            error: false,
            errorMessage: null,
            showHideButton: false,
            loading: false,
        }
    },
    methods: {
        handleError: function(errorM, url) {
            this.errorMessage = errorM.error +": " + url
            this.error = true
            this.submit = false
            this.loading = false
        },

        detailsReady: function() {
            this.loading = false
            this.showHideButton = true
        },

        emptyClicked: function() {
            this.hideEmpty = !this.hideEmpty
            if(this.hideEmpty){
                this.emptyValue = "Show Empty Value"
            }else{
                this.emptyValue = "Hide Empty Value"
            }
        },

        viewReview: function() {
            if(this.submit) {
                this.showHideButton=false
                this.loading = false
                this.submit = false
                this.hideEmpty = true
                this.emptyValue = "Show Empty Value"
            }
            if(this.url != null){
                if(this.token != null){
                    if(this.id != null){
                        this.loading = true
                        this.error = false
                        let requestor = new Requestor()
                        let completeUrl = this.url + '/' + 'api/v4/reviews/' + this.id
                        let token = 'Token ' + this.token
                        return requestor.get(
                            completeUrl,
                            token,
                        ).then((response) => {
                                this.submit = true
                                this.requestList = response.data
                                this.submit = true
                        }).catch((error) => {
                            this.errorMessage = error.error +" !"
                            this.error = true
                            this.submit = false
                            this.loading = false
                        })
                    }
                }
            }else{
                this.submit = false
                this.loading = false
            }
        }
    }
}
</script>

<style>
    #content{
        width: 100%;
        text-align: center;
        margin-top: 3%;
    }
    
    .loader {
        display: inline-block;
        border: 3px solid #f3f3f3; /* Light grey */
        border-top: 3px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    .url{
        width: 60%;
        margin-left: 8px;
    }
    .token{
        width: 40%;
        margin-left: 8px;
    }
    .id{
        margin-left: 8px;
        width: 30%;
    }
    .error{
        color:crimson;
    }
</style>
