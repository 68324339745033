<template>
  <div>
    <div :id="item_uuid" v-if="data_ready" class="dataShowing">
      <div class="dheader">
        <a style="font-size: 20px; pointer-events:none"><b>{{ item_type_name }}</b></a>
        -- {{
          itemGroup.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
        }}
        <h4>
          <b><a style="color: #337ab7" :href="iurl" target="_blank">{{ item_name }}</a></b>
        </h4>
      </div>
      <div :id="item_name">
        <table class="table table-hover" id="mainTable">
          <tbody v-for="k in Object.keys(itemData)" :key="k.index">
            <Details-render
              :token="token"
              :url="url"
              :label="k"
              :detail="itemData[k]"
              :hideEmpty="hideEmpty"
              :grouping="grouping"
              :itemuuid="item_uuid"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DetailsRender from "@/components/DetailsRender.vue";

export default {
  components: {
    DetailsRender,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    hideEmpty: {
      type: Boolean,
      required: true,
    },
    itemGroup: {
      type: String,
      required: true,
    },
    itemSet: {
      type: Object,
      required: true,
    },
    grouping: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      itemData: null,
      item_type_name: null,
      item_name: null,
      item_uuid: null,
      data_ready: false,
      // url for item
      iurl: String
    };
  },
  created: function () {
    this.iurl = this.url + "/item/" + this.item["id"];
    this.item_type_name = this.item["item_type_name"];
    let u = this.item["uuid"]
    if(this.itemSet[u]){
        let d = this.itemSet[u]
        this.itemData =  Object.keys(d).sort().reduce((obj, key) => {
          obj[key] = d[key];
          return obj;
        }, {})
        this.item_name = d["name"]
        this.item_uuid = d["uuid"]
    }
    this.data_ready = true
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("ready");
    });
  },
};
</script>

<style scoped>
a {
  color: inherit;
}

.loader {
  display: inline-block;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

.dataShowing {
  width: 100%;
  text-align: left;
}

.table > tbody {
  border-top: 0.5px;
}

.table > tbody > tr > th {
  padding-right: 0.75rem;
}

#mainTable > tbody > tr > td {
  word-break: normal;
}
</style>
